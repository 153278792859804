import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import ReactMarkdown from "react-markdown";
import { FaFilePdf, FaFileExcel, FaFileCsv, FaFilePowerpoint, FaFileWord, FaCopy } from 'react-icons/fa';
import nerdyAILogo from './Logo.png'; 
import { FaBars } from 'react-icons/fa';

const ChatWithDocuments = () => {
  const [query, setQuery] = useState("");
  const [chat, setChat] = useState(() => {
    const savedChat = localStorage.getItem("chatHistory");
    return savedChat ? JSON.parse(savedChat) : [];
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState("");
  const [sessionStarted, setSessionStarted] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [category, setCategory] = useState("documents");
  const scrollableDivRef = useRef(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!localStorage.getItem("session_id")) {
      startSession("upload");
    }
  }, []);

  useEffect(() => {
    if (errorVisible) {
      const timer = setTimeout(() => {
        setUploadMessage("");
        setErrorVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorVisible]);

  const startSession = async (route) => {
    try {
      const response = await fetch(`https://nerdyai.tech/api/start-session`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ route }),
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("session_id", data.session_id);
        setSessionStarted(true);
        if (route === "upload") sendWelcomeMessage();
      } else {
        console.error("Failed to start session:", data.error);
      }
    } catch (error) {
      console.error("Error starting session:", error);
    }
  };

  const sendWelcomeMessage = () => {
    if (chat.length === 0 && sessionStarted) {
      const welcomeMessage = { sender: "bot", message: "**Hey, I'm NerdyAI!** Feel free to ask me about your documents." };
      setChat((prevChat) => [...prevChat, welcomeMessage]);
    }
  };

  const handleQuery = async (event) => {
    event.preventDefault();
    if (!query.trim()) {
      const emptyQueryMessage = { sender: "bot", message: "Please enter a query to proceed." };
      setChat((prevChat) => [...prevChat, emptyQueryMessage]);
      return;
    }

    const newUserMessage = { sender: "user", message: query };
    setChat((prevChat) => [...prevChat, newUserMessage]);
    setQuery("");

    try {
      const session_id = localStorage.getItem("session_id");
      const res = await axios.post(
        "https://nerdyai.tech/api/query",
        { query, category },
        {
          headers: { "x-access-token": session_id },
          withCredentials: true,
        }
      );

      const responseMessage = res.data?.response?.answer ?? "Please try to upload your document first to chat with me!";
      const newBotMessage = { sender: "bot", message: responseMessage };
      setChat((prevChat) => [...prevChat, newBotMessage]);
    } catch (error) {
      const errorMessage = { sender: "bot", message: "There was an error processing your query. Please try again." };
      setChat((prevChat) => [...prevChat, errorMessage]);
      console.error("Error querying bot:", error);
    }
  };

  const clearChatHistory = () => {
    setChat([]);
    localStorage.removeItem("chatHistory");
    const readyMessage = { sender: "bot", message: "**Hi there! I'm NerdyAI.** Try uploading a document, then feel free to ask me anything!" };
    setChat((prevChat) => [...prevChat, readyMessage]);
  };

  useEffect(() => {
    localStorage.setItem("chatHistory", JSON.stringify(chat));
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chat]);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      setUploadMessage("Please choose a document to upload.");
      setErrorVisible(true);
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    for (let file of selectedFiles) {
      formData.append("files", file);
    }
    try {
      const session_id = localStorage.getItem("session_id");
      const response = await fetch("https://nerdyai.tech/api/upload", {
        method: "POST",
        headers: {
          "x-access-token": session_id,
        },
        body: formData,
        credentials: "include",
      });
      const data = await response.json();
      if (response.ok) {
        setUploadMessage("Files uploaded successfully!");
        setCurrentFile(Array.from(selectedFiles).map(file => file.name).join(", "));
        const readyMessage = { sender: "bot", message: "**I am ready to talk about your file now!** Feel free to ask and I will help you with it." };
        setChat((prevChat) => [...prevChat, readyMessage]);
      } else {
        setUploadMessage(data.error || "Failed to upload files.");
        setErrorVisible(true);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploadMessage("Failed to upload files. Please try again.");
      setErrorVisible(true);
    }
    setIsLoading(false);
  };

  const getFileIcon = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf': return <FaFilePdf className="text-red-500 text-2xl mr-2" />;
      case 'xlsx':
      case 'xls': return <FaFileExcel className="text-green-500 text-2xl mr-2" />;
      case 'csv': return <FaFileCsv className="text-blue-500 text-2xl mr-2" />;
      case 'pptx': return <FaFilePowerpoint className="text-orange-500 text-2xl mr-2" />;
      case 'docx': return <FaFileWord className="text-blue-700 text-2xl mr-2" />;
      case 'txt': return <FaFileWord className="text-gray-500 text-2xl mr-2" />;
      default: return null;
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => alert("Copied to clipboard!"))
      .catch(err => console.error("Failed to copy text:", err));
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col lg:flex-row">
      {/* File Upload Section */}
      <div className="w-full lg:w-1/4 flex flex-col p-4 bg-white rounded-lg shadow-md border-r-2 border-gray-300">
        <div className="relative mb-4">
          <button
            onClick={toggleMenu}
            className="absolute top-2 right-2 text-gray-700 hover:text-gray-900 p-2 rounded-full"
          >
            <FaBars size={24} />
          </button>
          {menuOpen && (
            <div className="absolute top-12 right-4 bg-white shadow-lg rounded-lg border border-gray-200 z-50">
              <ul className="flex flex-col">
                <li>
                  <a
                    href="/chat-websites"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    onClick={() => setMenuOpen(false)}
                  >
                    Chat with Websites
                  </a>
                </li>
                <li>
                  <a
                    href="/chat-youtube"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    onClick={() => setMenuOpen(false)}
                  >
                    Chat with YouTube
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
        <img src={nerdyAILogo} alt="NerdyAI Logo" className="w-24 h-24 mb-4" />
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="border-2 border-gray-300 rounded-lg px-4 py-2 bg-gray-50 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 mb-4"
        />
        <button
          onClick={handleUpload}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:-translate-y-1 duration-300 mb-4"
        >
          Upload Files
        </button>
        {isLoading && (
          <div className="text-gray-600">
            <progress className="w-full h-2" />
            Uploading...
          </div>
        )}
        {uploadMessage && (
          <p
            className={`mt-2 text-sm p-2 rounded-lg border-2 ${
              errorVisible ? "border-red-600 text-red-600" : "border-blue-600 text-blue-600"
            }`}
          >
            <strong>{uploadMessage}</strong>
          </p>
        )}
        <div className="w-full mt-4">
          {Array.from(selectedFiles).map((file, index) => (
            <div key={index} className="flex items-center mb-2">
              {getFileIcon(file.name)}
              <span className="ml-2 text-sm text-gray-700">{file.name}</span>
            </div>
          ))}
        </div>
        {currentFile && (
          <p className="mt-4 text-sm text-gray-600">You are chatting with file: <strong>{currentFile}</strong></p>
        )}
        <button
          onClick={clearChatHistory}
          className="mt-4 bg-red-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-700 transition-transform transform hover:-translate-y-1 duration-300"
        >
          Clear Chat
        </button>
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Supported Files:</h3>
          <div className="flex flex-col space-y-2">
            <div className="flex items-center">
              <FaFilePdf className="text-red-500 text-2xl mr-2" />
              <span>PDF</span>
            </div>
            <div className="flex items-center">
              <FaFileExcel className="text-green-500 text-2xl mr-2" />
              <span>Excel (XLSX, XLS)</span>
            </div>
            <div className="flex items-center">
              <FaFileCsv className="text-blue-500 text-2xl mr-2" />
              <span>CSV</span>
            </div>
            <div className="flex items-center">
              <FaFilePowerpoint className="text-orange-500 text-2xl mr-2" />
              <span>PowerPoint (PPTX)</span>
            </div>
            <div className="flex items-center">
              <FaFileWord className="text-blue-700 text-2xl mr-2" />
              <span>Word (DOCX)</span>
            </div>
            <div className="flex items-center">
              <FaFileWord className="text-gray-500 text-2xl mr-2" />
              <span>Text (TXT)</span>
            </div>
          </div>
        </div>
      </div>

      {/* Full-Screen Chat Display */}
      <div className="flex-grow lg:w-3/4 bg-white p-6 rounded-lg shadow-md flex flex-col">
        <div className="flex-grow overflow-y-auto" style={{ height: "80vh" }}>
          {chat.map((message, index) => (
            <div
              key={index}
              className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"} mb-4 w-full`}
            >
              {message.sender === "bot" && (
                <div className="relative flex items-start">
                  <img
                    src="./logo.png"
                    alt="Bot Logo"
                    className="w-8 h-8 mr-2"
                  />
                  <div className="relative">
                    <div
                      className={`max-w-4xl p-4 rounded-lg shadow-md break-words ${
                        message.sender === "user"
                          ? "bg-gray-200 text-black self-end"
                          : "bg-blue-100 text-black self-start"
                      }`}
                      style={{ wordWrap: 'break-word', fontSize: '1rem' }}
                    >
                      <ReactMarkdown>
                        {typeof message.message === 'object' ? JSON.stringify(message.message) : message.message}
                      </ReactMarkdown>
                    </div>
                    <button
                      onClick={() => copyToClipboard(message.message)}
                      className="absolute top-0 right-0 p-1 bg-blue-300 rounded-full shadow-md hover:bg-green-400"
                    >
                      <FaCopy size={16} />
                    </button>
                  </div>
                </div>
              )}
              {message.sender === "user" && (
                <div
                  className={`max-w-4xl p-4 rounded-lg shadow-md break-words bg-gray-200 text-black self-end`}
                  style={{ wordWrap: 'break-word', fontSize: '1rem' }}
                >
                  <ReactMarkdown>
                    {typeof message.message === 'object' ? JSON.stringify(message.message) : message.message}
                  </ReactMarkdown>
                </div>
              )}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        {/* Query Input and Submit */}
        <form
          onSubmit={handleQuery}
          className="w-full flex items-center bg-white p-4 rounded-lg shadow-md border-t border-gray-300"
        >
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Type your message here! and if you want to ask no relative question type (ignore context and .... your question )"
            className="flex-grow border-2 border-gray-300 rounded-l-lg p-4 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-300 transition duration-300"
          />
          <button
            type="submit"
            className="bg-blue-600 text-white px-6 py-4 rounded-r-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center"
          >
            <Icon icon="formkit:submit" className="text-2xl mr-2" />
            <span className="hidden md:inline">Send</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatWithDocuments;
