import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../components/logo.svg'; // Adjust the path as needed
import { FaFilePdf, FaGlobe, FaYoutube } from 'react-icons/fa'; // Import icons
import TypingAnimation from './TypingAnimation'; // Import the TypingAnimation component

const Home = () => {
  const navigate = useNavigate();

  // Define the text phases
  const phases = [
    { text: 'Your intelligent assistant for interacting with' },
    { text: 'Documents,| Websites,| YouTube Content' },
    { text: 'Explore, learn, and get answers effortlessly!' },
  ];

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      {/* Main Hero Section */}
      <div className="flex-grow flex flex-col items-center justify-center text-center text-gray-900 p-8 rounded-lg shadow-xl bg-white">
        <img src={logo} alt="Logo" className="h-32 w-auto mb-8" />
        <div className="relative flex flex-col items-center">
          <h1 className="text-4xl sm:text-6xl font-bold mb-4 text-blue-700">
            Nerdy AI
          </h1>
          <p className="text-base sm:text-lg font-bold italic text-gray-700">
            <TypingAnimation phases={phases} speed={50} />
          </p>
        </div>
        <div className="mt-8 space-y-4">
          <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
            <button
              onClick={() => navigate("/chat-documents")}
              className="bg-purple-600 text-white px-6 py-3 rounded-full font-semibold text-base shadow-md hover:bg-purple-700 transition-all duration-300 flex items-center"
            >
              <FaFilePdf className="mr-2 text-xl" /> Chat with Documents
            </button>
            <button
              onClick={() => navigate("/chat-websites")}
              className="bg-green-600 text-white px-6 py-3 rounded-full font-semibold text-base shadow-md hover:bg-green-700 transition-all duration-300 flex items-center"
            >
              <FaGlobe className="mr-2 text-xl" /> Chat with Website
            </button>
            <button
              onClick={() => navigate("/chat-youtube")}
              className="bg-red-600 text-white px-6 py-3 rounded-full font-semibold text-base shadow-md hover:bg-red-700 transition-all duration-300 flex items-center"
            >
              <FaYoutube className="mr-2 text-xl" /> Chat with YouTube
            </button>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="flex-grow overflow-y-auto p-4 bg-gray-100 flex items-center justify-center">
        <div className="flex flex-wrap gap-8 justify-center max-w-full mx-auto px-4 lg:px-8">
          {/* For Students */}
          <div className="bg-white p-8 rounded-lg shadow-md flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 lg:w-1/4 transform transition-transform duration-300 hover:scale-105 focus-within:ring-4 ring-blue-200">
            <div className="flex items-start space-x-4 mb-4">
              <span className="text-5xl text-blue-600">🎓</span>
              <div>
                <h3 className="text-lg font-semibold text-blue-600 mb-2">For Students</h3>
                <p className="text-gray-700">
                  Enhance your study sessions and homework help with interactive support from various document types, including .pptx, .docs, and more, along with YouTube videos and websites.
                </p>
              </div>
            </div>
          </div>

          {/* For Researchers */}
          <div className="bg-white p-8 rounded-lg shadow-md flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 lg:w-1/4 transform transition-transform duration-300 hover:scale-105 focus-within:ring-4 ring-blue-200">
            <div className="flex items-start space-x-4 mb-4">
              <span className="text-5xl text-blue-600">📖</span>
              <div>
                <h3 className="text-lg font-semibold text-blue-600 mb-2">For Researchers</h3>
                <p className="text-gray-700">
                  Effortlessly access and analyze academic papers, research articles, and various document types such as .pptx and .docs with our tool.
                </p>
              </div>
            </div>
          </div>

          {/* For Professionals */}
          <div className="bg-white p-8 rounded-lg shadow-md flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 lg:w-1/4 transform transition-transform duration-300 hover:scale-105 focus-within:ring-4 ring-blue-200">
            <div className="flex items-start space-x-4 mb-4">
              <span className="text-5xl text-blue-600">💼</span>
              <div>
                <h3 className="text-lg font-semibold text-blue-600 mb-2">For Professionals</h3>
                <p className="text-gray-700">
                  Efficiently review contracts, financial reports, manuals, and training materials from diverse document types such as .pptx and .docs. Get quick insights from multiple sources.
                </p>
              </div>
            </div>
          </div>

          {/* Multi-File Chats */}
          <div className="bg-white p-8 rounded-lg shadow-md flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 lg:w-1/4 transform transition-transform duration-300 hover:scale-105 focus-within:ring-4 ring-blue-200">
            <div className="flex items-start space-x-4 mb-4">
              <span className="text-5xl text-blue-600">🗂</span>
              <div>
                <h3 className="text-lg font-semibold text-blue-600 mb-2">Multi-File Chats</h3>
                <p className="text-gray-700">
                  Organize and manage multiple files, including .pptx, .docs, and more, along with web content in a single chat session. Easily navigate through your documents.
                </p>
              </div>
            </div>
          </div>

          {/* Cited Sources */}
          <div className="bg-white p-8 rounded-lg shadow-md flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 lg:w-1/4 transform transition-transform duration-300 hover:scale-105 focus-within:ring-4 ring-blue-200">
            <div className="flex items-start space-x-4 mb-4">
              <span className="text-5xl text-blue-600">📄</span>
              <div>
                <h3 className="text-lg font-semibold text-blue-600 mb-2">Cited Sources</h3>
                <p className="text-gray-700">
                  Get accurate answers with direct references to the original sources in various document types and websites. No more manual searching.
                </p>
              </div>
            </div>
          </div>

          {/* Any Language */}
          <div className="bg-white p-8 rounded-lg shadow-md flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 lg:w-1/4 transform transition-transform duration-300 hover:scale-105 focus-within:ring-4 ring-blue-200">
            <div className="flex items-start space-x-4 mb-4">
              <span className="text-5xl text-blue-600">🌍</span>
              <div>
                <h3 className="text-lg font-semibold text-blue-600 mb-2">Any Language</h3>
                <p className="text-gray-700">
                  Supports multiple languages for interacting with documents, websites, and videos. Seamlessly work in your preferred language.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
