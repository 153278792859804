import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import ChatWithDocuments from './components/ChatWithDocuments';
import ChatWithWebsites from './components/ChatWithWebsites';
import ChatWithYouTube from './components/ChatWithYouTube';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chat-documents" element={<ChatWithDocuments />} />
        <Route path="/chat-websites" element={<ChatWithWebsites />} />
        <Route path="/chat-youtube" element={<ChatWithYouTube />} />
        {/* Redirect any undefined routes to the home page */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
