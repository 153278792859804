import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import youtubeIcon from "@iconify/icons-mdi/youtube";
import ReactMarkdown from "react-markdown";
import nerdyAILogo from './Logo.png'; // Ensure your logo is imported
import { FaBars, FaCopy } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ChatWithYouTube = () => {
  const [query, setQuery] = useState("");
  const [chat, setChat] = useState(() => {
    const savedChat = localStorage.getItem("chatHistory");
    return savedChat ? JSON.parse(savedChat) : [];
  });
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [youtubeEmbedUrl, setYoutubeEmbedUrl] = useState("");
  const [processingMessage, setProcessingMessage] = useState("");
  const [isLoading, setIsLoading] = useState(() => JSON.parse(localStorage.getItem("isLoading")) || false);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const scrollableDivRef = useRef(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!localStorage.getItem("session_id")) {
      startSession();
    }
  }, []);

  useEffect(() => {
    if (errorVisible) {
      const timer = setTimeout(() => {
        setProcessingMessage("");
        setErrorVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorVisible]);

  useEffect(() => {
    localStorage.setItem("chatHistory", JSON.stringify(chat));
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chat]);

  useEffect(() => {
    localStorage.setItem("isLoading", JSON.stringify(isLoading));
  }, [isLoading]);

  const startSession = async () => {
    try {
      const response = await fetch("https://nerdyai.tech/api/start-session", {
        method: "POST",
        credentials: "include",
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("session_id", data.session_id);
        setSessionStarted(true);
        sendWelcomeMessage();
      } else {
        console.error("Failed to start session:", data.error);
      }
    } catch (error) {
      console.error("Error starting session:", error);
    }
  };

  const sendWelcomeMessage = () => {
    if (chat.length === 0 && sessionStarted) {
      const welcomeMessage = { sender: "bot", message: "**Hey, I'm NerdyAI!** Feel free to ask me about the YouTube content." };
      setChat((prevChat) => [...prevChat, welcomeMessage]);
    }
  };

  const handleQuery = async (event) => {
    event.preventDefault();
    if (!query.trim()) {
      const emptyQueryMessage = { sender: "bot", message: "Please enter a query to proceed." };
      setChat((prevChat) => [...prevChat, emptyQueryMessage]);
      return;
    }

    const newUserMessage = { sender: "user", message: query };
    setChat((prevChat) => [...prevChat, newUserMessage]);
    setQuery("");

    try {
      const session_id = localStorage.getItem("session_id");
      const res = await axios.post(
        "https://nerdyai.tech/api/query",
        { query, category: "youtube" },
        {
          headers: { "x-access-token": session_id },
          withCredentials: true,
        }
      );

      const responseMessage = res.data?.response?.answer ?? "Please process a YouTube video first to chat with me!";
      const newBotMessage = { sender: "bot", message: responseMessage };
      setChat((prevChat) => [...prevChat, newBotMessage]);
    } catch (error) {
      const errorMessage = { sender: "bot", message: "Please process a YouTube video first to chat with me!" };
      setChat((prevChat) => [...prevChat, errorMessage]);
      console.error("Error querying bot:", error);
    }
  };

  const clearChatHistory = () => {
    setChat([]);
    localStorage.removeItem("chatHistory");
    const readyMessage = { sender: "bot", message: "**Hi there! I'm NerdyAI.** Try processing a YouTube video, then feel free to ask me anything!" };
    setChat((prevChat) => [...prevChat, readyMessage]);
  };

  const handleUrlChange = (event) => {
    setYoutubeUrl(event.target.value);
  };

  const isValidYouTubeUrl = (url) => {
    const videoPattern = /^(https:\/\/www\.youtube\.com\/watch\?v=|https:\/\/youtu\.be\/)[\w-]{11}/;
    const shortsPattern = /^(https:\/\/www\.youtube\.com\/shorts\/)[\w-]{11}/;
    const invalidPattern = /(channel|playlist)/;
    return (videoPattern.test(url) || shortsPattern.test(url)) && !invalidPattern.test(url);
  };

  const extractVideoId = (url) => {
    const videoIdMatch = url.match(/(?:https:\/\/www\.youtube\.com\/watch\?v=|https:\/\/youtu\.be\/|https:\/\/www\.youtube\.com\/shorts\/)([\w-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };

  const handleProcessYouTube = async () => {
    if (!youtubeUrl.trim()) {
      setProcessingMessage("Please enter a YouTube URL.");
      setErrorVisible(true);
      return;
    }

    if (!isValidYouTubeUrl(youtubeUrl)) {
      setProcessingMessage("Please enter a valid YouTube video URL (not a playlist or channel).");
      setErrorVisible(true);
      return;
    }

    setProcessingMessage("Processing... Please wait. It takes approximately two to five minutes to handle the video process. Please Don't use other chats when youtube processing to handle your request");
    setIsLoading(true);

    try {
      const session_id = localStorage.getItem("session_id");
      const videoId = extractVideoId(youtubeUrl);
      const response = await axios.post(
        "https://nerdyai.tech/api/youtube",
        { url: youtubeUrl, save_dir: "youtube_audio", local: false },
        {
          headers: { "x-access-token": session_id },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setProcessingMessage("YouTube video processed successfully!");
        setYoutubeEmbedUrl(`https://www.youtube.com/embed/${videoId}`);
        const readyMessage = { sender: "bot", message: "**I am ready to talk about the YouTube content now!** Feel free to ask and I will help you with it." };
        setChat((prevChat) => [...prevChat, readyMessage]);
      } else {
        setProcessingMessage(response.data.error || "Failed to process YouTube video.");
        setErrorVisible(true);
      }
    } catch (error) {
      console.error("Error processing YouTube video:", error);
      setProcessingMessage("Failed to process YouTube video. Please try again.");
      setErrorVisible(true);
    }
    setIsLoading(false);
  };

  const toggleMainMenu = () => {
    setMainMenuOpen(prev => !prev);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col lg:flex-row">
      {/* Sidebar for Mobile */}
      <div className="w-full lg:w-1/3 flex flex-col p-4 bg-white rounded-lg shadow-md border-r-2 border-gray-300 relative">
        <div className="flex items-center mb-4">
          <img src={nerdyAILogo} alt="NerdyAI Logo" className="w-16 h-16" />
          <Icon icon={youtubeIcon} className="w-16 h-16 ml-4 text-red-600" />
        </div>

        {/* Main Menu Button */}
        <button
          onClick={toggleMainMenu}
          className="mt-4 bg-blue-400 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:-translate-y-1 duration-300"
        >
          Main Menu
        </button>
        {mainMenuOpen && (
          <div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-lg absolute top-20 right-0 w-48 z-10">
            <ul>
              <li>
                <Link to="/chat-with-youtube" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                  Chat with YouTube
                </Link>
              </li>
              <li>
                <Link to="/chat-with-documents" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                  Chat with Documents
                </Link>
              </li>
              <li>
                <Link to="/chat-with-websites" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                  Chat with Websites
                </Link>
              </li>
            </ul>
          </div>
        )}

        <input
          type="text"
          value={youtubeUrl}
          onChange={handleUrlChange}
          placeholder="Enter YouTube URL"
          className="border-2 border-gray-300 rounded-lg px-4 py-2 bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 transition duration-300 mb-4"
          disabled={isLoading}
        />
        <button
          onClick={handleProcessYouTube}
          className={`bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md transition-transform transform hover:-translate-y-1 duration-300 mb-4 ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-blue-700'}`}
          disabled={isLoading}
        >
          Process YouTube Video
        </button>
        {isLoading && (
          <div className="text-gray-600">
            <progress className="w-full h-2" />
            Processing...
          </div>
        )}
        {processingMessage && (
          <p
            className={`mt-2 text-sm ${
              errorVisible ? "text-red-600 border-2 border-red-600 p-2 rounded-lg" : "text-gray-600"
            }`}
          >
            <strong>{processingMessage}</strong>
          </p>
        )}
        <button
          onClick={clearChatHistory}
          className={`mt-4 bg-red-600 text-white px-4 py-2 rounded-lg shadow-md transition-transform transform hover:-translate-y-1 duration-300 ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-red-700'}`}
          disabled={isLoading}
        >
          Clear Chat
        </button>
      </div>

      {/* YouTube Video Embed and Chat Display */}
      <div className="flex-grow lg:w-2/3 flex flex-col p-6 rounded-lg shadow-md bg-white">
        {youtubeEmbedUrl && (
          <div className="flex justify-center mb-4">
            <iframe
              width="100%"
              height="315"
              src={youtubeEmbedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
        )}
        <div className="flex-grow overflow-y-auto flex flex-col-reverse" style={{ height: "0vh" }}>
          {chat.map((message, index) => (
            <div
              key={index}
              className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"} mb-4 w-full`}
            >
              {message.sender === "bot" && (
                <img
                  src="./logo.png"
                  alt="Bot Logo"
                  className="w-8 h-8 mr-2"
                />
              )}
              <div
                className={`relative max-w-4xl p-4 rounded-lg shadow-md break-words ${
                  message.sender === "user" ? "bg-gray-200 text-gray-800" : "bg-blue-100 text-gray-800"
                }`}
                style={{ whiteSpace: "pre-wrap", overflowWrap: "anywhere", paddingRight: "2.5rem" }}
              >
                <ReactMarkdown>{message.message}</ReactMarkdown>
                {message.sender === "bot" && (
                  <button
                    onClick={() => copyToClipboard(message.message)}
                    className="absolute right-2 top-2 bg-gray-300 text-gray-800 px-2 py-1 rounded-lg hover:bg-gray-400 transition-colors"
                  >
                    <FaCopy />
                  </button>
                )}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        {/* User Input and Submit Button */}
        <form onSubmit={handleQuery} className="flex mt-4">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Type your question here..."
            className="flex-grow border-2 border-gray-300 rounded-lg px-4 py-2 bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 transition duration-300"
            disabled={isLoading}
          />
          <button
            type="submit"
            className={`ml-4 bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md transition-transform transform hover:-translate-y-1 duration-300 ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-blue-700'}`}
            disabled={isLoading}
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatWithYouTube;
