import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import nerdyAILogo from "./Logo.png"; // Import the NerdyAI logo
import websiteWorldwideLogo from "./WebsiteWorldwideLogo.png"; // Import the Website Worldwide logo
import { FaBars } from 'react-icons/fa'; // Import menu icon
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { FaCopy } from 'react-icons/fa'; // Import copy icon

const ChatWithWebsites = () => {
  const [query, setQuery] = useState("");
  const [chat, setChat] = useState(() => {
    const savedChat = localStorage.getItem("websitesChatHistory");
    return savedChat ? JSON.parse(savedChat) : [];
  });
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [processingMessage, setProcessingMessage] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mainMenuOpen, setMainMenuOpen] = useState(false); // Main menu state
  const scrollableDivRef = useRef(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!localStorage.getItem("websites_session_id")) {
      startSession();
    }
  }, []);

  useEffect(() => {
    if (errorVisible) {
      const timer = setTimeout(() => {
        setProcessingMessage("");
        setErrorVisible(false);
      }, 5000); // Hide error message after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [errorVisible]);

  const startSession = async () => {
    try {
      const response = await fetch("https://nerdyai.tech/api/start-session", {
        method: "POST",
        credentials: "include",
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("websites_session_id", data.session_id);
        setSessionStarted(true);
        sendWelcomeMessage();
      } else {
        console.error("Failed to start session:", data.error);
      }
    } catch (error) {
      console.error("Error starting session:", error);
    }
  };

  const sendWelcomeMessage = () => {
    if (chat.length === 0 && sessionStarted) {
      const welcomeMessage = { sender: "bot", message: "**Hey, I'm NerdyAI!** Feel free to ask me about the website content." };
      setChat((prevChat) => [...prevChat, welcomeMessage]);
    }
  };

  const handleQuery = async (event) => {
    event.preventDefault();
    if (!query.trim()) {
      const emptyQueryMessage = { sender: "bot", message: "Please enter a query to proceed." };
      setChat((prevChat) => [...prevChat, emptyQueryMessage]);
      return;
    }

    const newUserMessage = { sender: "user", message: query };
    setChat((prevChat) => [...prevChat, newUserMessage]);
    setQuery("");

    try {
      const session_id = localStorage.getItem("websites_session_id");
      const res = await axios.post(
        "https://nerdyai.tech/api/query",
        { query, category: "website" },
        {
          headers: { "x-access-token": session_id },
          withCredentials: true,
        }
      );

      const responseMessage = res.data?.response?.answer ?? "Please provide a website URL first.";
      const newBotMessage = { sender: "bot", message: responseMessage };
      setChat((prevChat) => [...prevChat, newBotMessage]);
    } catch (error) {
      const errorMessage = { sender: "bot", message: "There was an error processing your query. Please try again." };
      setChat((prevChat) => [...prevChat, errorMessage]);
      console.error("Error querying bot:", error);
    }
  };

  const handleUrlSubmit = async () => {
    if (!websiteUrl.trim()) {
      setProcessingMessage("Please enter a valid website URL.");
      setErrorVisible(true);
      return;
    }

    // Validate URL format
    const urlPattern = /^(https?:\/\/)/i;
    if (!urlPattern.test(websiteUrl)) {
      setProcessingMessage("Failed to process website content. Please provide a valid website link starting with http like https://example.com.");
      setErrorVisible(true);
      return;
    }

    setProcessingMessage("Processing... Please wait.");
    setIsLoading(true);

    try {
      const session_id = localStorage.getItem("websites_session_id");
      const response = await fetch("https://nerdyai.tech/api/process-website", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": session_id,
        },
        body: JSON.stringify({ url: websiteUrl }),
        credentials: "include",
      });

      const data = await response.json();
      if (response.ok) {
        setProcessingMessage("Website content processed and embeddings created.");
        const successMessage = { sender: "bot", message: "**I am ready to chat about the website content now!** Feel free to ask me anything about it." };
        setChat((prevChat) => [...prevChat, successMessage]);
      } else {
        setProcessingMessage(data.error || "Failed to process website URL.");
        setErrorVisible(true);
      }
    } catch (error) {
      console.error("Error processing website:", error);
      setProcessingMessage("Failed to process website URL. Please try again.");
      setErrorVisible(true);
    }
    setIsLoading(false);
  };

  const clearChatHistory = () => {
    setChat([]);
    localStorage.removeItem("websitesChatHistory");
    const readyMessage = { sender: "bot", message: "**Hi there! I'm NerdyAI.** Enter a website URL, then feel free to ask me anything about it!" };
    setChat((prevChat) => [...prevChat, readyMessage]);
  };

  useEffect(() => {
    localStorage.setItem("websitesChatHistory", JSON.stringify(chat));
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chat]);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleMainMenu = () => setMainMenuOpen(!mainMenuOpen);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log("Text copied to clipboard");
    }).catch(err => {
      console.error("Failed to copy text: ", err);
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col lg:flex-row">
      {/* Sidebar with Main Menu Button */}
      <div className="w-full lg:w-1/4 flex flex-col p-4 bg-white rounded-lg shadow-md border-r-2 border-gray-300">
        <div className="flex items-center mb-4">
          <img src={nerdyAILogo} alt="NerdyAI Logo" className="w-16 h-16 mr-2" />
          <img src={websiteWorldwideLogo} alt="Website Worldwide Logo" className="w-16 h-16" />
        </div>
        <input
          type="text"
          value={websiteUrl}
          onChange={(e) => setWebsiteUrl(e.target.value)}
          placeholder="Enter website URL"
          className="border-2 border-gray-300 rounded-lg px-4 py-2 bg-gray-50 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 mb-4"
          disabled={isLoading}
        />
        <button
          onClick={handleUrlSubmit}
          className={`bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:-translate-y-1 duration-300 mb-4 ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-blue-700'}`}
          disabled={isLoading}
        >
          Process Website
        </button>
        {isLoading && (
          <div className="text-gray-600">
            <progress className="w-full h-2" />
            Processing...
          </div>
        )}
        {processingMessage && (
          <p
            className={`mt-2 text-sm p-2 rounded-lg border-2 ${
              errorVisible ? "border-red-600 text-red-600" : "border-blue-600 text-blue-600"
            }`}
          >
            <strong>{processingMessage}</strong>
          </p>
        )}
        <button
          onClick={clearChatHistory}
          className={`mt-4 bg-red-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-700 transition-transform transform hover:-translate-y-1 duration-300 ${isLoading ? 'bg-gray-400 cursor-not-allowed' : ''}`}
          disabled={isLoading}
        >
          Clear Chat
        </button>
        {/* Main Menu Button */}
        <button
          onClick={toggleMainMenu}
          className="mt-4 bg-green-100 text-gray-700 px-4 py-2 rounded-lg shadow-md hover:bg-green-300 transition-transform transform hover:-translate-y-1 duration-300"
        >
          Main Menu
        </button>
        {mainMenuOpen && (
          <div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-lg">
            <ul>
              <li>
                <Link
                  to="/chat-documents"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  onClick={() => setMainMenuOpen(false)}
                >
                  Chat with Documents
                </Link>
              </li>
              <li>
                <Link
                  to="/chat-youtube"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  onClick={() => setMainMenuOpen(false)}
                >
                  Chat with YouTube
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Main Chat Area */}
      <div className="flex-grow lg:w-3/4 bg-white p-6 rounded-lg shadow-md flex flex-col">
        {/* Menu Button for Mobile */}
        <div className="lg:hidden mb-4">
          <button
            onClick={toggleMenu}
            className="bg-blue-400 text-white p-2 rounded-full shadow-md hover:bg-blue-700 transition-transform transform hover:-translate-y-1 duration-300"
          >
            <FaBars />
          </button>
          {menuOpen && (
            <div className="absolute top-16 left-4 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
              <ul>
                <li>
                  <Link
                    to="/chat-documents"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    onClick={() => setMenuOpen(false)}
                  >
                    Chat with Documents
                  </Link>
                </li>
                <li>
                  <Link
                    to="/chat-youtube"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    onClick={() => setMenuOpen(false)}
                  >
                    Chat with YouTube
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>

        <div className="flex-grow overflow-y-auto" style={{ height: "80vh" }}>
          {chat.map((message, index) => (
            <div
              key={index}
              className={`relative flex ${message.sender === "user" ? "justify-end" : "justify-start"} mb-4 w-full`}
            >
              <div
                className={`relative max-w-4xl p-4 rounded-lg shadow-md break-words ${
                  message.sender === "user"
                    ? "bg-gray-200 text-black self-end"
                    : "bg-blue-100 text-black self-start"
                }`}
                style={{ wordWrap: 'break-word', fontSize: '1rem', paddingRight: '2.5rem' }}
              >
                <ReactMarkdown>
                  {typeof message.message === 'object' ? JSON.stringify(message.message) : message.message}
                </ReactMarkdown>
                {message.sender === "bot" && (
                  <button
                    onClick={() => copyToClipboard(message.message)}
                    className="absolute top-1 right-1 p-1 bg-blue-300 rounded-full shadow-md hover:bg-green-400"
                  >
                    <FaCopy />
                  </button>
                )}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        {/* Query Input and Submit */}
        <form
          onSubmit={handleQuery}
          className="w-full flex items-center bg-white p-4 rounded-lg shadow-md border-t border-gray-300"
        >
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Type your message here! and if you want to ask no relative question type (ignore context and .... your question )"
            className="flex-grow border-2 border-gray-300 rounded-l-lg p-4 bg-gray-50 focus:outline-none focus:ring-2 focus:blue-300 transition duration-300"
            disabled={isLoading}
          />
          <button
            type="submit"
            className={`bg-blue-700 text-white px-6 py-4 rounded-r-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center ${isLoading ? 'bg-gray-400 cursor-not-allowed' : ''}`}
            disabled={isLoading}
          >
            <span className="hidden md:inline">Send</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatWithWebsites;
