import React, { useState, useEffect } from 'react';

const TypingAnimation = ({ phases, speed }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [phaseIndex, setPhaseIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    if (charIndex < phases[phaseIndex].text.length) {
      const timer = setTimeout(() => {
        setDisplayedText(prev => prev + phases[phaseIndex].text[charIndex]);
        setCharIndex(prev => prev + 1);
      }, speed);

      return () => clearTimeout(timer);
    } else {
      const phaseTimer = setTimeout(() => {
        setCharIndex(0);
        setDisplayedText('');
        setPhaseIndex(prev => (prev + 1) % phases.length);
      }, 2000); // Pause before moving to the next phase

      return () => clearTimeout(phaseTimer);
    }
  }, [charIndex, phases, phaseIndex, speed]);

  const getStyledText = (text) => {
    // Define the regex patterns for matching the words
    const patterns = [
      { word: 'documents', color: 'text-purple-600' },
      { word: 'websites', color: 'text-green-600' },
      { word: 'YouTube', color: 'text-red-600' },
    ];

    // Create a regex pattern to match any of the words
    const regex = new RegExp(patterns.map(p => `\\b${p.word}\\b`).join('|'), 'gi');

    // Replace matched words with span elements having the appropriate classes
    return text.split(regex).map((part, index) => {
      const match = text.match(regex)?.[index];
      if (match) {
        const pattern = patterns.find(p => p.word.toLowerCase() === match.toLowerCase());
        return (
          <span key={index} className={pattern.color}>
            {match}
          </span>
        );
      }
      return part;
    });
  };

  return <p>{getStyledText(displayedText)}</p>;
};

export default TypingAnimation;
